* {
  font-family: Arial;
}

app-root {
  position: relative;
  display: block;
  min-height: 100%;
}
.footer-padding {
  padding-bottom: 50px;
  @media screen and (max-width: 576px) {
    padding-bottom: 140px;
  }
}

html {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Arial;
}

body {
  background: url(/assets/images/body.jpg);
  background-repeat: repeat;
  height: 100%;
}

.button {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

.button.button-yellow {
  font-family: 'TSTARPRO', Arial, Sans-Serif;
  background-color: #d20028;
  color: #fff;
  display: inline-block;
  padding: 9px 20px 6px 20px;
  text-align: center;
  font-size: 20px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border: 0;
  cursor: pointer;
}

.button-yellow:hover {
  color: #fff;
}

.button.button-disabled {
  font-family: 'TSTARPRO', Arial, Sans-Serif;
  background-color: transparent;
  display: inline-block;
  padding: 7px 20px 6px 20px;
  text-align: center;
  font-size: 20px;
  border: 1px solid #6c6c6c;
  color: #6c6c6c;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  cursor: pointer;
}

.button.button-disabled::before {
  content: '';
  display: none;
}

.button:before {
  content: url(/assets/images/icons/Zoller_Icon_Arrow_white.svg);
  pointer-events: none;
  display: inline-block;
  width: 13px;
  height: 13px;
  right: 6px;
  position: relative;
}

.info-svg {
  max-width: 28px;
  display: inline-block;
  float: left;
  position: relative;
  top: 2px;
  margin-right: 10px;
}

.info-svg-cancel {
  max-width: 28px;
  display: inline-block;
  float: left;
  position: relative;
  top: -4px;
  margin-right: 10px;
}
