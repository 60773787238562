/* Nx Defaults */
$primary: #3fbab4;
$warning: #e5d64b;
$error: #ff7150;
$success: #3fbab4;

/* Theme Colors */
$base-white: #ffffff;
$base-black: #000000;
$base-yellow: #f0e600;
$base-black-10: #ededed;
$base-black-15: #f2f2f2;
$base-black-20: #dadada;
$base-black-50: #9d9d9d;
$base-black-70: #6c6c6c;
$base-black-80: #575757;

$font-size: 16px;

$grid-breakpoints: (
  xxs: 0,
  xs: 375px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1921px,
);

$container-max-widths: (
  xs: 375px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1920px,
);
