@import 'variables';

.dx-datagrid-addrow-button {
    background-color: $primary;
    border:none;
    color: #fff;
    &:hover {
        background-color: #3ABED5;
    }
    .dx-icon {
        color: #fff;
    }
}