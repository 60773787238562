/* You can add global styles to this file, and also import other style files */
@charset "UTF-8";

// https://sass-guidelin.es/#architecture
// 7-1 Pattern

// Configuration and helpers
@import 'variables';
// @import 'placeholders';
// @import 'mixins';

@import 'base/bootstrap.min';

// Vendors

@import 'vendors/generated/dx.theme';
@import 'vendors/w3';

// // Base stuff

//@import 'base/styles';
// @import 'base/layout';

// // Theming
// @import 'themes/common';
// @import 'themes/w3-theme-primary';
// @import 'themes/w3-theme-secondary';
// @import 'themes/w3-theme-tertiary';
// @import 'themes/w3-theme-danger';

// Overrides
@import 'overrides/devextreme';
// @import 'overrides/w3';

// import zoller globals etc
@import 'global_ezset';
